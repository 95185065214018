<template>
	<div>
		<el-dialog title="资产详情" @close="closeDialog" :visible.sync="dialogVisible" width="1150px" custom-class="width_800 cus_dialog">
			<div v-for="(info,idx) in DataList" :key="idx">
				<el-tabs v-model="activeName" type="card">
				    <el-tab-pane label="资产详情" name="first"></el-tab-pane>
				    <el-tab-pane label="操作日志" name="second"></el-tab-pane>
				    
				  </el-tabs>
				  
				  
				<table class="pntable" cellspacing="0" v-if="activeName=='first'">
					<tr>
						<td style="width: 20%;" class="r1td ct">资产名称</td>
						<td class="r1td rlsttd" style="width: 80%;">{{info.title}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">资产编码</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.zc_code}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">资产分类</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.zc_typeparent}}/{{info.typename}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">所在位置</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.storename3}}/{{info.storename2}}/{{info.storename}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">购置方式</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.zc_gztype}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">购置日期</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.zc_date}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">品牌</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.pinpai}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">型号</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.xinghao}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">计量单位</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.jldw}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">价值金额</td>
						<td class="nmtd rlsttd" style="width: 80%;">¥{{info.jine}}元</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">状态</td>
						<td class="nmtd rlsttd" style="width: 80%;">
							<span v-if="info.status==0" class="st0">闲置</span>
							<span v-if="info.status==1" class="st1">领用</span>
							<span v-if="info.status==2" class="st2">派发</span>
							<span v-if="info.status==3" class="st3">借用</span>
							<span v-if="info.status==4" class="st4">租赁已退还</span>
							<span v-if="info.status==5" class="st5">维修中</span>
							<span v-if="info.status==-1" class="st-1">报废</span>
							<span v-if="info.last_username" style="margin-left: 20px;">当前使用人：{{info.last_username}}</span>
						</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">备注</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.zcdesc}}</td>
					</tr>
					
					
					<tr>
						<td style="width: 20%;" class="nmtd ct">附件</td>
						<td class="nmtd rlsttd" style="width: 80%;">
							<div>
								<!-- <a href="javascript:;" @click="uploadPreview(f)">{{f.filename}}</a> -->
								<div class="updimage" v-for="(f,idx) in info.files" :key="idx" :style="{backgroundImage:'url('+f.src+')'}">
									<img :src="f.src" onclick="viewImage(this)" style="opacity: 0;" />
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">维保供应商</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.wb_gxy}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">维保联系人</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.wb_gxy}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">维保电话</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.wb_phone}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">维保到期时间</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.wb_deadline}}</td>
					</tr>
					<tr>
						<td style="width: 20%;" class="nmtd ct">维保说明</td>
						<td class="nmtd rlsttd" style="width: 80%;">{{info.wb_desc}}</td>
					</tr>

				</table>
				
				<table class="pntable" cellspacing="0" v-if="activeName=='second'">
					<tr>
						
						<td class="r1td rlsttd">操作日志</td>
					</tr>
					<tr v-for="(log,i) in info.logs">
						
						<td class="nmtd rlsttd">【{{log.cdate}}】{{log.czdesc}}</td>
					</tr>
					</table>
			</div>
		</el-dialog>


	</div>
</template>
<script>
	export default {
		props: ['id'],
		data() {
			return {
				activeName:'first',
				DataList: [],
				dialogVisible: true
			}
		},
		mounted() {
			this.userinfo = JSON.parse(localStorage.getItem("user"))
			if (this.id) {
				this.getList()
			}
		},
		methods: {
			closeDialog() {
				this.$parent.dialogInfoShow = false
			},
			getList() {
				this.$http.post("/api/zc_zcinfo", {
					id: this.id
				}).then(res => {
					try{
						res.data.files = JSON.parse(res.data.files)
					}catch(e){
						res.data.files=[];
					}
					this.DataList = [res.data];
				})
			}


		}
	}
</script>
<style scoped="scoped">
.updimage{
		width: 50px; height: 50px; display: inline-block; vertical-align: top; margin: 0 10px 10px 0; border: 1px dotted #ccc;
		background-size: 100% auto;
		background-position: center;
		overflow: hidden;
		background-repeat: no-repeat;
		cursor: pointer;
	}
</style>
